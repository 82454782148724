import UnitDetails, { getServerSideProps as gSSP } from '../../components/pages/UnitDetail';
import React, { FC } from 'react';
import { brandConfig } from '../_app';
import { GetServerSideProps } from 'next';

const UnitDetailsNL: FC<React.PropsWithChildren<{ id?: string }>> = props => (
    <UnitDetails key={props.id} pageSlug="accommodatie" {...props} />
);
export default UnitDetailsNL;

export const getServerSideProps: GetServerSideProps = ctx => gSSP(brandConfig, ctx);
